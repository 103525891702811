import { Navbar, Footer } from "./layout/index";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsNConditions from "./pages/TermsNConditions";
import {
  Landing,
  AboutUs,
  Blogs,
  Career,
  Contact,
  FBCDifference,
  Services,
} from "./pages/index";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/FBC_Difference" element={<FBCDifference />} />
        <Route path="/services" element={<Services />} />
        {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
        <Route path="/terms-and-conditions" element={<TermsNConditions />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
