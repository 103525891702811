export const Unique =[
    {
        index:"1.",
        description:"Experienced Management"
    },
    {
        index:"2.",
        description:"Robust Learning and Development Process"
    },
    {
        index:"3.",
        description:"Customer Service and Healthcare Specialist"
    },
    {
        index:"4.",
        description:"Technology Enabled Solutions"
    },
    {
        index:"5.",
        description:"Fast Onboarding"
    },
    {
        index:"6.",
        description:"Multiple Delivery Centers"
    },
    {
        index:"7.",
        description:"Multi-lingual Capabilities"
    },
]

export  const HeroEngagementCardData = [
  {
    SubTitle: "Staff Augmentation",
    description:
      "FBC will help organisations fill in for staff shortages by providing rapid access to missing skills and capabilities",
    path: "assets/Staff.png",
  },
  {
    SubTitle: "Outsourcing",
    description:
      "Provider manages and has full access to center's operations, assumes delivery risk and productivity incentives.",
    path: "assets/Outsatnding.png",
  },
  {
    SubTitle: "Recruitment Process Outsourcing (RPO)",
    description:
      "This model helps companies to fulfill their internal resources or manpower requirements.",
    path: "assets/RecruitmentProcess.png",
  },
  {
    SubTitle: "Seat Leasing",
    description:
      "This model is solely for seat rental. The client manages and has full access to center's operations.",
    path: "assets/Seat 1.png",
  },
  {
    SubTitle: "Build, Operate and Transfer (BOT)",
    description:
      "This model benefits companies that does not have an exsitng registered entity in the Philippines but plans to have a registered entity in the future.",
    path: "assets/BOT 1.png",
  },
];

export const SharingCardData = [
    {
        Title:"Business Process Outsourcing",
        description:"Everything You Need to Know About Human Resources Business Process Outsourcing (BPO)",
        date:"January 26, 2023",
        path:"assets/BuisnessProcess.png"
    },
    {
        Title:"Manage Your Team Offshore",
        description:"5 Best Practices to Manage Your Team Offshore",
        date:"December 8, 2022",
        path:"assets/ManageTeam.png"
    },
    {
        Title:"Outsourcing vs. Near-shoring vs. Offshoring",
        description:"Outsourcing vs. Nearshoring vs. Offshoring: Which one suits your business best?",
        date:"November 4, 2022",
        path:"assets/blog-3.png"
    },
]

export const ServiceTab = [
  {
    label: "Customer Service",
    path: "assets/CustomerService1.png",
  },
  {
    label: "Inbound Sales",
    path: "assets/CustomerService2.png",
  },
  {
    label: "Technical Help Desk",
    path: "assets/CustomerService3.png",
  },
  {
    label: "Virtual Assistance",
    path: "assets/CustomerService4.png",
  },
];

export const ServiceTabTwo = [
  {
    label: "Lead Generation",
    path: "assets/CustomerService5.png",
  },
  {
    label: "Telemarketing",
    path: "assets/CustomerService6.png",
  },
  {
    label: "Surveys",
    path: "assets/CustomerService7.png",
  },
  {
    label: "Appointment Setting",
    path: "assets/CustomerService8.png",
  },
  {
    label: "Third Party Verification",
    path: "assets/CustomerService9.png",
  },
];

export const TestimonialData = [
  {
    id: 1,
    path: "assets/testimonial.png",
    companyLogo: "assets/testimonialLogo.png",
    desc: "We have observed a significant improvement in productivity and quality since partnering with FBC. Your adaptability and flexibility to accommodate rapid changes at short notice are highly appreciated. The Manila team is able to learn new processes within a very short time frame which has benefitted both HRC and our clients. ",
    Name: "Dr. Rilwan Ismail",
    title: "Vice President - RCM Operations.",
  },
  {
    id: 2,
    path: "assets/testimonial2.png",
    companyLogo: "assets/testimonialLogo2.png",
    desc: "As you well know, your reputation in the industry was a significant reason for our partnership and you and your team did not disappoint and exceeded our expectations. All the very best and rest assured that if ever we need any further assistance, we will definitely consider your services.",
    Name: "Mr.Carlos Hernandez",
    title: "Country Manager",
  },
  {
    id: 3,
    path: "assets/testimonial3.png",
    companyLogo: "assets/cocp.png",
    desc: "The results from the FBC Asia Pacific have been exceptional. The management team should be very proud of the working environment they have created. Their staff has reported much higher levels of job satisfaction compared to both local Filipino and international.",
    Name: "Mr.Ian Aitchison",
    title: "CEO of COPC Inc - Asia Pacific Region",
  },
  // {
  //   id: 3,
  //   path: "assets/testimonial3.png",
  //   companyLogo: "assets/testimonialLogo.png",
  //   desc: "I have worked closely with FBC and had a great experience. They are friendly, and have worked tirelessly to fulfill our needs. The team is responsive and helpful and I feel that I can count on them as a strong business partner.",
  //   Name: "Mr.Casey Arnold ",
  //   title: "Vice President of RCM Operations",
  // },
];

export const ImagesSlider = [
  {
    id: 1,
    path: "assets/Hero_Image.png",
  },
  {
    id: 2,
    path: "assets/slider.png",
  },
];

export const sponsors = [
  {
    id: 1,
    path: "assets/sponsor1.png",
  },
  {
    id: 2,
    path: "assets/sponsor2.png",
  },
  {
    id: 1,
    path: "assets/sponsor1.png",
  },
  {
    id: 2,
    path: "assets/sponsor2.png",
  },
  {
    id: 1,
    path: "assets/sponsor1.png",
  },
  {
    id: 2,
    path: "assets/sponsor2.png",
  },
];