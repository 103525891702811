import React, { useEffect, useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { navlinks } from "../routes/routes";

const Navbar = () => {
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const toggleIcons = () => {
    setShowCloseIcon((prevState) => !prevState);
  };

  const [active, setActive] = useState(0);
  function handleSetActive(index) {
    setActive(index);
    console.log(index);
    setShowCloseIcon(false);
    window.scrollTo(0, 0);
  }

  const menuVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        opacity: { duration: 0.3 },
        y: { type: "spring", stiffness: 300, damping: 30 },
      },
    },
    closed: {
      opacity: 0,
      y: "-100%",
      transition: {
        opacity: { duration: 0.3 },
        y: { type: "spring", stiffness: 300, damping: 30 },
      },
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const handlePopstate = () => {
      const currentPath = window.location.pathname;

      const activeIndex = navlinks.findIndex(
        (link) => link.path === currentPath
      );

      setActive(activeIndex);
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <header className="bg-[#0C2E4E] lg:px-20 Neue fixed w-full z-10 ">
      <div className="container mx-auto flex  p-4 flex-row items-center justify-between  w-full ">
        <Link to="/" onClick={() => handleSetActive(0)}>
          <div className="w-40">
            <img src="/assets/Logo.png" alt="logo" className="object-contain" />
          </div>
        </Link>
        <div className="sm:block hidden">
          <nav className=" flex  items-center text-base  flex-row justify-evenly gap-9 text-white">
            {navlinks.map((link, index) => (
              <div
                key={index}
                onClick={() => handleSetActive(index)}
                className="cursor-pointer"
              >
                <Link
                  className="cursor-pointer hover:text-[#FF731D] active:text-[#FF731D]"
                  path={link.value}
                  to={link.path}
                >
                  {link.title}
                </Link>
                {active === index && (
                  <div className="bg-[#FF731D] rounded-full hidden sm:block w-1 h-1 my-1 sm:mx-auto " />
                )}
              </div>
            ))}
          </nav>
        </div>
        <div className="sm:hidden block">
          <div className="flex flex-row gap-3 items-center">
            <div onClick={toggleIcons}>
              {showCloseIcon ? (
                <AiOutlineClose
                  className="text-white cursor-pointer sm:hidden block"
                  size={25}
                />
              ) : (
                <AiOutlineMenu
                  className="text-white cursor-pointer sm:hidden block"
                  size={25}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showCloseIcon && (
        <div className="relative h-full w-full bg-main py-5 flex flex-col justify-center items-center gap-9 z-10">
          <nav className=" flex  items-center text-base flex-col justify-evenly gap-9 text-white">
            {navlinks.map((link, index) => (
              <div key={index} onClick={() => handleSetActive(index)}>
                <Link
                  className="cursor-pointer"
                  path={link.value}
                  to={link.path}
                >
                  {link.title}
                </Link>
                {active === index && (
                  <div className="bg-white rounded-full hidden sm:block w-1 h-1 my-1 sm:mx-auto" />
                )}
              </div>
            ))}
          </nav>
        </div>
      )}
    </header>
  );
};

export default Navbar;
