
export const navlinks = [
  {
    title: "Home",
    path: "/",
    value: 0,
  },
  {
    title: "About Us",
    path: "/about",
    value: 1,
  },
  {
    title: "Services",
    path: "/services",
    value: 2,
  },
  {
    title: "FBC Difference",
    path: "/FBC_Difference",
    value: 3,
  },
  // {
  //   title: "Blog",
  //   path: "/blog",
  //   value: 4,
  // },
  {
    title: "Careers",
    path: "/careers",
    value: 5,
  },
  {
    title: "Contact",
    path: "/contact",
    value: 6,
  },
];
