import React from "react";

const TermsNConditions = () => {
  const handleLinkClick = () => {
    // Redirect to the specified URL
    window.location.href = "https://www.facebook.com/policy/cookies/";
  };
  return (
    <div className="h-full w-full p-6 sm:p-24 bg-white flex flex-col gap-0 lg:gap-10">
      <div className="sm:h-[30vh] h-[40vh] w-full bg-white flex">
        <span className="flex justify-start my-auto text-3xl sm:text-5xl mx-suto sm:mx-0 font-bold lg:w-2/3 text-left">
          FBC ASIA Terms <br className="block lg:hidden" /> of Services
        </span>
      </div>
      <div className="flex flex-col gap-5 ">
        <span className="text-lg font-bold">
          Privacy Policy This Privacy Policy sets out the practices that FBC
          Asia Pacific Inc (“FBC”) follows with respect to the collection, use
          maintenance and disclosure of information collected through
          www.fbcapac.com (the Site”). This Privacy Policy applies only to
          information we collect from this Site and does not apply to any other
          website or business activity of FBC. By using our Site, you consent to
          the collection, use, maintenance and disclosure of this information by
          us.
        </span>
        <span className="text-lg font-bold">
          FBC reserves the right to revise this policy at any time. In the event
          of a revision to this policy, we will give notice of the revised
          Privacy Policy by posting it on the site.
        </span>
        <span className="text-2xl font-bold">About This Policy</span>
        <span className="text-lg font-bold text-[#666666]">
          We collect “personally identifiable information” and “usage
          information” from individuals who visit our Site.
          <br />
          <br />
          “Personally identifiable information” is information about an
          identifiable individual such as the individual’s name, phone number,
          physical address, e-mail address. “Usage information” is information
          automatically collected by our web server made available by your web
          browser whenever you visit or use our Site, such as your computer’s IP
          address, browser type, operating system version and geolocation
          information.
          <br />
          <br /> We may collect personally identifiable information when you
          contact us via our Site or register with our Site and you voluntarily
          choose to provide them to us. For example, we may collect your name,
          phone number, physical address and e-mail address.
          <br />
          <br /> We may collect “usage information” anytime you visit, view
          and/or interact with our Site or its pages and features. We may
          collect your computer’s IP address, browser type, operating system
          version, geolocation information, and information regarding your
          browsing activities (e.g., parts of the website visited, items clicked
          on). We may associate this information with your personally
          identifiable information.
          <br />
          <br /> This Site may also use “cookies” and other technologies
          enabling us to recognize users and customize their experience and
          obtain information regarding the use of the Site. A cookie which is a
          small text file that a website’s server places on a user’s computer,
          mobile phone or other device enables to transmit information back to
          the website’s server regarding the browsing activities of the computer
          user on the Site. This may also include information such as pages and
          content viewed, the time and duration of visits and whether the user
          clicked on an advertisement. Cookies are also used to recognize users
          and maintain data related to a particular individual. If you prefer
          not to accept cookies from our Site, you can modify the settings on
          your web browser.
        </span>
        <span className="text-2xl font-bold">Information We May Collect</span>
        <span className="text-lg font-bold text-[#666666]">
          We collect “personally identifiable information” and “usage
          information” from individuals who visit our Site.
          <br />
          <br /> “Personally identifiable information” is information about an
          identifiable individual such as the individual’s name, phone number,
          physical address, e-mail address. “Usage information” is information
          automatically collected by our web server made available by your web
          browser whenever you visit or use our Site, such as your computer’s IP
          address, browser type, operating system version and geolocation
          information.
          <br />
          <br />
          We may collect personally identifiable information when you contact us
          via our Site or register with our Site and you voluntarily choose to
          provide them to us. For example, we may collect your name, phone
          number, physical address and e-mail address.
          <br />
          <br /> We may collect “usage information” anytime you visit, view
          and/or interact with our Site or its pages and features. We may
          collect your computer’s IP address, browser type, operating system
          version, geolocation information, and information regarding your
          browsing activities (e.g., parts of the website visited, items clicked
          on). We may associate this information with your personally
          identifiable information.
          <br />
          <br /> This Site may also use “cookies” and other technologies
          enabling us to recognize users and customize their experience and
          obtain information regarding the use of the Site. A cookie which is a
          small text file that a website’s server places on a user’s computer,
          mobile phone or other device enables to transmit information back to
          the website’s server regarding the browsing activities of the computer
          user on the Site. This may also include information such as pages and
          content viewed, the time and duration of visits and whether the user
          clicked on an advertisement. Cookies are also used to recognize users
          and maintain data related to a particular individual. If you prefer
          not to accept cookies from our Site, you can modify the settings on
          your web browser.
        </span>

        <span className="text-2xl font-bold">Third Party Cookies</span>
        <span className="text-lg font-bold text-[#666666] flex items-center">
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="2" fill="#000000" />
          </svg>{" "}
          Google Analytics
        </span>
        <span className="text-lg font-medium text-[#666666]">
          {" "}
          FBC employs Google Analytics, a web-analysis tool from Google Inc.
          (“Google”) which enables us to optimize your user experience. Google
          Analytics uses cookies that will track your preferences when you visit
          our website. This helps us make our Site more user-friendly. The
          information gathered through this process is anonymous and makes
          personal identification impossible.
        </span>
        <span className="text-lg font-bold text-[#666666] flex items-center">
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="2" fill="#000000" />
          </svg>
          Facebook Inc.
        </span>
        <span className="text-lg font-medium text-[#666666]">
          When you use our services through Facebooks’s web platform, Facebook
          may use cookies to gather data on its users. You can read further
          about Facebook’s cookies policy in the following link;
        </span>
        <span
          className="text-lg font-medium text-red-400 cursor-pointer"
          onClick={handleLinkClick}
        >
          https://www.facebook.com/policy/cookies/
        </span>

        <span className="text-2xl font-bold">Use of Information</span>
        <span className="text-lg font-bold text-[#666666]">
          Personally identifiable information gathered from this Site shall only
          be used for the purpose for which they are provided and for any other
          purposes set out in this Privacy Policy. We may use personally
          identifiable information for marketing, advertising and promotional
          purposes or to respond to emails and other inquiries.
          <br />
          <br /> Usage information will be used to help us run and maintain our
          Site, customize our users’ experience, study traffic patterns, and
          familiarize ourselves about the usage of the Site.
          <br />
          <br /> Cookies shall maintain information about your use of our Site,
          help us identify when you visit our Site again and enable us to
          evaluate and improve our Site layout.
        </span>
        <span className="text-2xl font-bold">Disclosure of Information</span>
        <span className="text-lg font-bold text-[#666666]">
          We may provide personally identifiable information to third parties
          who provide services to us. We may provide personally identifiable
          information to third parties who handle marketing and other
          communications on our behalf. We may also disclose personally
          identifiable information to our parent company, affiliated companies
          or sister companies.
          <br />
          <br /> We may use and disclose personally identifiable information and
          other information that we collect when there is reason to believe that
          using and/or disclosing this information is necessary to investigate,
          identify, contact, or take action against someone who may have engaged
          in illegal activities or breached the Terms of Service applicable to
          the Site or who may be causing injury to or interference with our
          rights or property, other users of our Site, or anyone else who could
          be harmed by such activities. We may also disclose personally
          identifiable information when such disclosure is otherwise required or
          permitted by law, regulation or rule.
        </span>
        <span className="text-2xl font-bold">Privacy of Children</span>
        <span className="text-lg font-bold text-[#666666]">
          The Site is a general audience website. We do not intentionally
          collect personally identifiable information from users of this Site
          who are under the age of 13.
        </span>
        <span className="text-2xl font-bold">
          How We Secure Your Information
        </span>
        <span className="text-lg font-bold text-[#666666]">
          We secure your information using appropriate technical and
          organisational measures such as storing your information on secure
          servers, encrypting transfers of data to or from our servers using
          Secure Sockets Layer (SSL) technology, only granting access to your
          information where necessary.
        </span>
        <span className="text-2xl font-bold">
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="2" fill="#000000" />
          </svg>
          Your Rights in Relation to Your Information
        </span>
        <span className="text-lg font-bold text-[#666666] flex items-center gap-1">
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="2" fill="#000000" />
          </svg>
          To access your information and to receive information about its use.
        </span>
        <span className="text-lg font-bold text-[#666666] flex items-center gap-1">
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="2" fill="#000000" />
          </svg>
          To have your information corrected and/or completed.
        </span>
        <span className="text-lg font-bold text-[#666666] flex items-center gap-1">
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="2" fill="#000000" />
          </svg>
          To have your information deleted.
        </span>
        <span className="text-lg font-bold text-[#666666] flex items-center gap-1">
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="2" fill="#000000" />
          </svg>
          To restrict the use of your information.
        </span>
        <span className="text-lg font-bold text-[#666666] flex items-center gap-1">
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="2" fill="#000000" />
          </svg>
          To receive your information in a portable format.
        </span>
        <span className="text-lg font-bold text-[#666666] flex items-center gap-1">
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="2" fill="#000000" />
          </svg>
          To object to the use of your information
        </span>
        <span className="text-lg font-bold text-[#666666] flex items-center gap-1">
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="2" fill="#000000" />
          </svg>
          To withdraw your consent to the use of your information
        </span>
        <span className="text-lg font-bold text-[#666666] flex items-center gap-1">
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="2" fill="#000000" />
          </svg>
          To complain to a supervisory authority
        </span>
      </div>
    </div>
  );
};

export default TermsNConditions;
