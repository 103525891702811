export const buttons_Mission =[
    {
        "id": 1,
        text:"All"
    },
    {
        "id": 2,
        text:"HR"
    },
    {
        "id": 3,
        text:"Team Leader"
    },
    {
        "id": 4,
        text:"Admin"
    },
    {
        "id": 5,
        text:"Finance"
    },
    {
        "id": 6,
        text:"IT"
    }
]