import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const LandingForm = () => {
  const form = useRef();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_vmcrkdl",
        "template_usxbdld",
        form.current,
        "36zIQl-R_B7uNpb3G"
      )
      .then(
        (result) => {
          console.log(result.text);
          resetForm();
          setFormSubmitted(true);
          showAlert("success", "Form submitted successfully!");
        },
        (error) => {
          console.log(error.text);
          showAlert("error", "An error occurred. Please try again later.");
        }
      );
  };

  const resetForm = () => {
    form.current.reset();
  };

  const showAlert = (type, message) => {
    alert(`${type.toUpperCase()}: ${message}`);
  };

  return (
    <div className="bg-[#0C2E4E] flex flex-col items-start lg:py-28 py-12 px-4 w-full gap-4">
      <div className="flex flex-col  mx-auto">
        <div className="text-white lg:text-5xl font-semibold lg:text-left text-center text-2xl py-8">
          Connect with Us
        </div>
        <div className="text-base text-center lg:text-left text-white pb-8">
          Begin your outsourcing experience with a reliable partner.
        </div>
        {formSubmitted ? (
          <div className="text-white text-center">
            Thank you for submitting the form!
          </div>
        ) : (
          <form className="text-white" ref={form} onSubmit={sendEmail}>
            {/* Form fields */}
            <div className="flex flex-col lg:flex-row lg:gap-8 gap-2 justify-center items-center lg:w-[895px] w-full pt-2 lg:py-4">
              {/* First Name */}
              <div className="w-full">
                <input
                  type="text"
                  placeholder="First Name"
                  className="w-full h-[55px] rounded-lg px-4 border border-white bg-transparent"
                  name="firstName"
                  required
                />
              </div>
              {/* Last Name */}
              <div className="w-full ">
                <input
                  type="text"
                  placeholder="Last Name"
                  className="w-full h-[55px] rounded-lg px-4 border border-white bg-transparent"
                  name="lastName"
                  required
                />
              </div>
            </div>
            {/* Message */}
            <div className="lg:w-[895px] w-full lg:py-4 pt-2 pb-4">
              <div className="w-full">
                <input
                  type="text"
                  placeholder="Message"
                  className="w-full h-[96px] rounded-lg px-4 border border-white bg-transparent"
                  name="message"
                  required
                />
              </div>
            </div>
            {/* Submit Button */}
            <div className="flex mx-auto">
              <input
                type="submit"
                value="Submit"
                className="bg-[#FF731D] w-[160px] h-[50px] rounded-lg text-white font-semibold cursor-pointer"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default LandingForm;
