import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ImagesSlider } from "../../../constants/HeroData";

const Image = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: 6,
    },
    laptop: {
      breakpoint: { max: 1500, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 770 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 770, min: 550 },
      items: 2,
    },
    minMobile: {
      breakpoint: { max: 550, min: 0 },
      items: 2,
    },
  };

  const images = [
    {
      path: "",
      alt: "Client 1",
    },
    {
      path: "assets/Hero_Image2.png",
      alt: "Client 2",
    },
  ];

  if (window.innerWidth >= 768) {
    return (
      <div className="flex flex-col">
        <Carousel
          className="banner-carousel"
          autoPlay={true}
          autoPlaySpeed={5000}
          showDots={false}
          infinite={true}
          responsive={{
            desktop: {
              breakpoint: { max: 9999999, min: 0 },
              items: 1,
            },
          }}
        >
          <div className="h-[336px] w-full">
            {ImagesSlider.map((images, i) => {
              return (
                <img
                  key={i}
                  src={images.path}
                  alt={images.id}
                  className="h-full w-full  object-cover"
                />
              );
            })}
          </div>
        </Carousel>
        <div className="absolute flex justify-center items-center left-0 right-0 top-0 bottom-80">
          <div className="text-white text-3xl font-bold lg:text-6xl md:4xl">
            Faster. Better. Champion.
          </div>
        </div>
      </div>
    );
  } else if (window.innerWidth < 768) {
    return (
      <div className="flex flex-col z-[-50]">
        <Carousel
          className="banner-carousel"
          autoPlay={true}
          autoPlaySpeed={5000}
          showDots={false}
          infinite={true}
          responsive={{
            desktop: {
              breakpoint: { max: 9999999, min: 0 },
              items: 1,
            },
          }}
          // customLeftArrow={<NavArrow type="left" />}
          // customRightArrow={<NavArrow type="right" />}
        >
          <div className="h-[336px] w-full">
            {ImagesSlider.map((images, i) => {
              return (
                <img
                  key={i}
                  src={images.path}
                  alt={images.id}
                  className="h-full w-full  object-cover"
                />
              );
            })}
          </div>
        </Carousel>
        <div className="absolute flex justify-center items-center left-0 right-0 top-0 bottom-40 ">
          <div className="text-white text-3xl font-bold lg:text-6xl md:4xl ">
            Faster. Better. Champion.
          </div>
        </div>
      </div>
    );
  }
};

export default Image;
