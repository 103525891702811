export const GovernanceData = [
  {
    pathImage: "assets/Rajaram.png",
    name: "M Rajaram",
    position: "Chairman",
  },
  {
    pathImage: "assets/alexis.png",
    name: "Alexis Lovell",
    position: "Board Member",
  },
  {
    pathImage: "assets/denis.png",
    name: "Denis McGee",
    position: "Board Member",
  },
  {
    pathImage: "assets/john.png",
    name: "John Pereira",
    position: "Board Member",
  },
  {
    pathImage: "assets/dinusha.png",
    name: "Dinusha Herath",
    position: "Company Director",
  },
  {
    pathImage: "assets/vinoth.png",
    name: "Vinoth Kumar Mohanadas",
    position: "Company Director",
  },
  {
    pathImage: "assets/anjali.png",
    name: "Anjali Rambukwella",
    position: "Group Corporate Secretary",
  },
];

export const ExecutiveManagementData = [
  {
    pathImage: "http://kernernorland.com/wp-content/uploads/2023/07/Iresh.png",
    name: "Iresh Bandara",
    position: "Global General Manager",
  },
  {
    pathImage: "assets/shyamali.png",
    name: "Shyamali Kirinde",
    position: "Head of Operations",
  },
  {
    pathImage: "assets/menon.png",
    name: "Menon Balakrishnan",
    position: "Director of Stakeholder Relations",
  },
  {
    pathImage: "assets/raiz.png",
    name: "Raaiz Quadir",
    position: "Managing Director, Sri Lanka",
  },
  {
    pathImage: "assets/shamil.png",
    name: "Shamil Shiraz",
    position: "Chief Financial Officer",
  },
  {
    pathImage: "assets/rizwan.png",
    name: "Rilwan Ismail",
    position: "",
  },
];

export const OperationsData = [
  // {
  //     pathImage:'assets/blank.png',
  //     name:'Joe Peeris',
  //     position:'Assistant Vice President',
  // },
  // {
  //     pathImage:'assets/blank.png',
  //     name:'Randhir Navaratnarajah',
  //     position:'Manager – Call Center Operations',
  // },Ruwintha Fonseka
  {
    pathImage: "assets/Ruwintha.png",
    name: "Ruwintha Fonseka",
    position: "Head of Operations",
  },
];

export const SupportData = [
  {
    pathImage: "assets/Nesel.png",
    name: "Nesel Casiano",
    position: "Head of Client Relations",
  },
  {
    pathImage: "assets/Mary.png",
    name: "Mary Del Argente",
    position: "HR Admin Associate",
  },
  {
    pathImage: "assets/Joname.png",
    name: "Jonamae Eusebio",
    position: "Finance Manager",
  },
  {
    pathImage: "assets/Maria.png",
    name: "Maria Patrice Garcia",
    position: "Head of Human Resource",
  },
];
