import React from "react";
import Image from "./Image";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const LandingImage = () => {
  return (
    <div className="flex flex-col">
      <Image />
      <div className="flex flex-col px-2 sm:px-24 py-12  text-center gap-y-8">
        <div className="text-[#464255] text-base lg:w-[780px] flex mx-auto">
          FBC Asia Pacific is an outsourced customer service provider offering
          call center services for inbound, outbound, and omni-channel support.
          We provide voice, chat, administrative, and specialist support
          services out of our delivery centers in the Philippines and Sri Lanka.
          Our engagement model is robust and transparent with cutting-edge
          remote working solutions.
        </div>
        <div className="pb-12">
          <button
            className="text-white w-[299px] h-[70px] bg-[#0C2E4E] rounded-lg font-semibold hover:scale-90 transform transition-all duration-300 ease-in-out border border-[#0C2E4E] hover:bg-white hover:text-[#0C2E4E]"
            onClick={() =>
              window.open("https://meetings.hubspot.com/nesel", "_blank")
            }
          >
            Book an Appointment
          </button>
        </div>
      </div>
      <div className="pb-16 px-40 hidden lg:block">
        <div className="mx-auto">
          <Carousel
            className="banner-carousel"
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            infinite={true}
            arrows={false}
            responsive={{
              desktop: {
                breakpoint: { max: 9999999, min: 0 },
                items: 4,
              },
            }}
            // customLeftArrow={<NavArrow type="left" />}
            // customRightArrow={<NavArrow type="right" />}
          >
            <div className="">
              <img src="assets/sponsor1.png" alt="sponsors" className="" />
            </div>
            <div className="">
              <img src="assets/sponsor2.png" alt="sponsors" className="" />
            </div>
            <div className="">
              <img src="assets/sponsor3.png" alt="sponsors" className="" />
            </div>
            <div className="">
              <img src="assets/sponsor4.png" alt="sponsors" className="" />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default LandingImage;
