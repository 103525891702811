export const contactAddressData = [
  {
    id: 1,
    country: "Philippines",
    addressIcon: "assets/location_Address.png",
    address:
      "FBC Asia Pacific Inc, 11th Floor One Corporate Center,Julia Vargas Avenue Ortigas Center,San San Antonio,Pasig City 1800,The Philippines",
    phoneIcon: "assets/address.png",
    phone: "+63 917 509 7752 (Globe & Whatsapp)",
    emailIcon: "assets/emailAddress.png",
    email: "info@fbcapac.com",
  },
  {
    id: 2,
    country: "Australia",
    addressIcon: "assets/location_Address.png",
    address:
      "FBC Asia Pacific Inc,Suite 3, 1044A Dandenong Rd,Carnegie VIC 3163",
    phoneIcon: "assets/address.png",
    phone: "+1300 228 724",
    emailIcon: "assets/emailAddress.png",
    email: "info@fbcapac.com",
  },
  {
    id: 3,
    country: "Singapore",
    addressIcon: "assets/location_Address.png",
    address: "FBC Asia Pacific Inc, 6 Battery Rd, Singapore 049909",
    phoneIcon: null,
    phone: "",
    emailIcon: null,
    email: "info@fbcapac.com",
  },
  {
    id: 4,
    country: "USA",
    addressIcon: "assets/location_Address.png",
    address: "1177 Avenue of the Americas, 5 Floor, New York, 10036",
    phoneIcon: null,
    phone: "",
    emailIcon: null,
    email: "info@fbcapac.com",
  },
  {
    id: 5,
    country: "Sri Lanka",
    addressIcon: "assets/location_Address.png",
    address: "BPO Connect Pvt. Ltd. is a BPO Company located in Colombo 01000",
    phoneIcon: "",
    phone: "+94 11 514 6699",
    emailIcon: "",
    email: "info@fbcapac.com",
  },
];