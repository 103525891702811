import React from 'react'

const LifeImageV2 = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 sm:py-12 py-0  mx-auto flex flex-wrap">
        <div className="flex flex-wrap md:-m-2 -m-1">
          <div className="flex flex-wrap w-1/2">
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery3"
                className="w-full object-cover h-full object-center block  rounded-lg"
                src="assets/image3.png"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block rounded-lg"
                src="assets/image4.png"
              />
            </div>
            <div className="md:p-2 p-1 w-full">
              <img
                alt="gallery"
                class="w-full h-full object-cover object-center block rounded-lg"
                src="assets/image2.png"
              />
            </div>
          </div>
          <div className="flex flex-wrap w-1/2">
            <div className="md:p-2 p-1 w-full">
              <img
                alt="gallery"
                className="w-full h-full object-cover object-center block rounded-lg"
                src="assets/image1.png"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block rounded-lg"
                src="assets/image5.png"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                className="w-full object-cover h-full object-center block rounded-lg"
                src="assets/image6.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LifeImageV2