import React from "react";
import EngagementCard from "../../../Crads/EngagementCard";
import { HeroEngagementCardData } from "../../../constants/HeroData";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; 

const LandingEngagementModel = () => {
 const responsive = {
   superLargeDesktop: {
     // the naming can be any, depends on you.
     breakpoint: { max: 4000, min: 3000 },
     items: 5,
   },
   desktop: {
     breakpoint: { max: 3000, min: 1024 },
     items: 3,
   },
   tablet: {
     breakpoint: { max: 1024, min: 464 },
     items: 2,
   },
   mobile: {
     breakpoint: { max: 464, min: 0 },
     items: 1,
   },
 };
  const CustomLeftArrow = ({ onClick }) => (
    <button onClick={() => onClick()} className="arrow-btn left-arrow">
      <FaChevronLeft />
    </button>
  );

  const CustomRightArrow = ({ onClick }) => (
    <button onClick={() => onClick()} className="arrow-btn right-arrow">
      <FaChevronRight />
    </button>
  );
  
  return (
    <div className="lg:px-32 px-8 lg:py-20 py-10 bg-[#F3F3F3] lg:bg-white ">
      <div className="flex flex-row lg:justify-between items-center mb-8">
        <div className="lg:text-5xl text-[18px] md:text-3xl font-semibold text-[#111439] text-center mx-auto sm:mx-0">
          Engagement Models
        </div>
        <Link to="/services">
          <div className="text-[#FF731D] text-xl font-semibold hidden border border-white lg:block hover:underline hover:border hover:border-[#FF731D] p-2 hover:rounded-lg">
            View More
          </div>
        </Link>
      </div>
      <div className="gap-8 w-full  justify-evenly">
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          showDots={true}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
        >
          {HeroEngagementCardData.map((items, key) => (
            <EngagementCard data={items} key={key} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default LandingEngagementModel;
