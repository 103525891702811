import React from 'react'

const AboutMap = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="lg:w-[985.61px] px-4 lg:px-0 py-20">
        <div className="text-[#111439] lg:text-5xl font-semibold text-2xl text-center pb-12">
          Think Global, Act Global
        </div>
        <div className="text-[#464255] text-base font-light text-center pb-12">
          Our network of specialized outsourced services supports businesses
          around the globe.
        </div>
        <div className='py-20'>
          <img src="assets/HeroMap.png" alt="Map" />
        </div>
        <div className="flex justify-center items-center pt-20">
          <button
            className="text-white w-[299px] h-[70px] bg-[#0C2E4E] rounded-lg font-semibold hover:scale-90 transform transition-all duration-300 ease-in-out border border-[#0C2E4E] hover:bg-white hover:text-[#0C2E4E]"
            onClick={() =>
              window.open("https://meetings.hubspot.com/nesel", "_blank")
            }
          >
            Work with Us
          </button>
        </div>
      </div>
    </div>
  );
}

export default AboutMap