import React from 'react'
import AboutImage from '../components/About/AboutImage'
import AboutOurStory from '../components/About/AboutOurStory'
import AboutNavigating from '../components/About/AboutNavigating'
import AboutOurTeam from '../components/About/AboutOurTeam'
import AboutMap from '../components/About/AboutMap'
import OurValues from '../components/Career/OurValues'

const AboutUs = () => {
  return (
    <div className="flex flex-col pt-14">
      <AboutImage />
      <AboutOurStory />
      <OurValues />
      <AboutNavigating />
      <AboutOurTeam />
      <AboutMap />
    </div>
  );
}

export default AboutUs