import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const ContactUsForm = () => {
  const form = useRef();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_uxnfwuf",
        "template_zfzj0hf",
        form.current,
        "1hvcWS_SIpbhdZmIl"
      )
      .then(
        (result) => {
          console.log(result.text);
          resetForm();
          setFormSubmitted(true);
          showAlert("success", "Form submitted successfully!");
        },
        (error) => {
          console.log(error.text);
          showAlert("error", "An error occurred. Please try again later.");
        }
      );
  };

  const showAlert = (type, message) => {
    alert(`${type.toUpperCase()}: ${message}`);
  };

  const resetForm = () => {
    form.current.reset();
  };

  return (
    <div className="bg-[#FAFAFA] p-4">
      <div className="text-[#111439] lg:text-5xl md:text-3xl text-2xl font-semibold text-center lg:text-left py-8 px-4">
        Connect with Us
      </div>
      {formSubmitted ? (
        <div className="text-center text-[#111439]">
          Thank you for submitting the form!
        </div>
      ) : (
        <form className="flex flex-col gap-4" ref={form} onSubmit={sendEmail}>
          <div className="flex lg:flex-row flex-col w-full  gap-4 px-4">
            <input
              type="text"
              placeholder="First Name *"
              className="lg:w-1/2 h-[52px]  border border-[#46425533] bg-transparent placeholder:bg-[#FAFAFA] rounded-lg px-4 outline-none"
              name="firstName"
              required
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />

            <input
              type="text"
              placeholder="Last Name *"
              className="lg:w-1/2 h-[52px] border border-[#46425533] bg-transparent placeholder:bg-[#FAFAFA] rounded-lg px-4 outline-none"
              name="lastName"
              required
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
          </div>
          <div className="flex lg:flex-row flex-col w-full  gap-4 px-4 ">
            <input
              type="text"
              placeholder="Comapany Name *"
              className="lg:w-1/2 h-[52px]  border border-[#46425533] bg-transparent placeholder:bg-[#FAFAFA] rounded-lg px-4 outline-none"
              name="companyName"
              required
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
            <input
              type="text"
              placeholder="Company Website *"
              className="lg:w-1/2 h-[52px] border border-[#46425533] bg-transparent placeholder:bg-[#FAFAFA] rounded-lg px-4 outline-none"
              name="companyWebsite"
              required
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
          </div>
          <div className="flex lg:flex-row flex-col w-full  gap-4 px-4">
            <input
              type="email"
              placeholder="Email *"
              className="lg:w-1/2 h-[52px]  border border-[#46425533] bg-transparent placeholder:bg-[#FAFAFA] rounded-lg px-4 outline-none"
              name="email"
              required
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />

            <input
              type="tel"
              placeholder="Contact Number*"
              className="lg:w-1/2 h-[52px] border border-[#46425533] bg-transparent placeholder:bg-[#FAFAFA] rounded-lg px-4 outline-none"
              name="contactNumber"
              required
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
          </div>
          <div className="px-4 h-[55px]">
            <select
              className="w-full bg-transparent h-[52px] rounded-lg outline-none border border-[#46425533] text-gray-400 px-3"
              name="engagementModel"
              onChange={(e) => {
                console.log(e.target.value);
              }}
            >
              <option value="" disabled selected>
                What engagement models are you int..
              </option>
              <option value="Self Augmentation">Self Augmentation</option>
              <option value="Outsourcing">Outsourcing</option>
              <option value="Recruitment Process Outsourcing">
                Recruitment Process Outsourcing
              </option>
            </select>
          </div>
          <div className="px-4 h-[55px]">
            <select
              className="w-full bg-transparent h-[52px] rounded-lg outline-none border border-[#46425533] text-gray-400 px-3"
              name="howDidYouFindUs"
              onChange={(e) => {
                console.log(e.target.value);
              }}
            >
              <option value="" disabled selected>
                How did you find out about FBC Asia?
              </option>
              <option value="Internet">Internet</option>
              <option value="Social Media">Social Media</option>
              <option value="From a Friend">From a Friend</option>
            </select>
          </div>
          <div className="px-4">
            <textarea
              placeholder="Message"
              className="text-black w-full h-[96px] lg:md:h-[116px] bg-transparent border border-[#46425533] rounded-lg outline-none px-4 py-4 "
              name="message"
              required
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
          </div>
          <div className="px-4 mx-auto lg:mx-0">
            <input
              type="submit"
              value="Send"
              className="bg-[#FF731D] text-white text-lg font-semibold rounded-lg h-[52px] w-full lg:w-[160px] mx-auto px-4 py-4 cursor-pointer"
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactUsForm;
