import React from 'react'
import { ServiceTab, ServiceTabTwo } from '../../../constants/HeroData';

const LandingOurServices = () => {
  return (
    <div className=" px-4 lg:py-20 py-12 flex flex-col lg:flex-row gap-2">
      <div className="flex lg:flex-col mx-auto flex-col gap-10">
        <div className="lg:w-full lg:max-w-full">
          <div className="lg:px-20 lg:pb-10">
            <div className="text-lg lg:text-5xl text-[#111439] font-semibold mb-8">
              Take a Look at Our Services
            </div>
            <div className="text-lg ">
              We provide professional and cost-effective outsourced inbound call
              center services that are flexible and scalable. Our service
              industries include telecom, insurance, healthcare, consulting, and
              accounting.
            </div>
            <div className="text-[111439] text-base font-medium text-left lg:py-10">
              Inbound Services
            </div>
            <div className="lg:w-full grid grid-cols-2 sm:grid-cols-4 gap-10">
              {ServiceTab.map((items, key) => (
                <div
                  key={key}
                  className="flex flex-col mx-12 items-center hover:scale-125 transition delay-100"
                >
                  <div className="">
                    <img src={items.path} alt="serivce icon" />
                  </div>
                  <div className="text-sm">{items.label}</div>
                </div>
              ))}
            </div>
            <div className="text-[111439] text-base font-medium text-left lg:py-10">
              Outbound Services
            </div>
            <div className="lg:w-full grid grid-cols-2 sm:grid-cols-4 gap-10">
              {ServiceTabTwo.map((items, key) => (
                <div
                  key={key}
                  className="flex flex-col mx-12 items-center hover:scale-125 transition delay-100"
                >
                  <div>
                    <img src={items.path} alt="serivce icon" />
                  </div>
                  <div className="text-sm">{items.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingOurServices