import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

import { BsFacebook, BsFillTelephoneFill } from "react-icons/bs";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";
const Footer = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_vmcrkdl",
        "template_pinxfcq",
        form.current,
        "36zIQl-R_B7uNpb3G"
      )
      .then(
        (result) => {
          console.log(result.text);
          resetForm();
          showAlert("success", "Subscribed successfully!");
        },
        (error) => {
          console.log(error.text);
          showAlert("error", "An error occurred. Please try again later.");
        }
      );
  };

  const showAlert = (type, message) => {
    alert(`${type.toUpperCase()}: ${message}`);
  };

  const resetForm = () => {
    form.current.reset();
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  if (window.innerWidth > 768) {
    return (
      <div className="relative">
        <div className="bg-white pt-24 relative">
          <form
            className="flex flex-col gap-4 rounded-xl lg:w-[780px] md:w-full w-full h-[251px] bg-[#FF731D] mx-auto relative top-10 justify-center items-center"
            form
            ref={form}
            onSubmit={sendEmail}
          >
            <div className="text-2xl text-white font-[600]">
              Stay up to Date
            </div>
            <div className="text-base text-white font-[300]">
              Subscribe to our monthly newsletter
            </div>
            <div className="flex flex-row gap-4 items-center">
              <div className="bg-[#FF731D] border rounded-md border-white">
                <input
                  type="email"
                  placeholder="Email"
                  id="footerEmail"
                  className="w-[292px] h-10 placeholder-white text-white bg-[#FF731D] rounded-md px-4 outline-none"
                  name="email"
                  required
                />
              </div>
              <input
                type="submit"
                value="Send"
                className="w-[160px] h-[42px] bg-white text-[#FF731D] rounded-md font-[600] cursor-pointer"
              />
            </div>
          </form>
        </div>
        <div className="bg-[#0C2E4E] px-20 grid grid-cols-4 gap-8 text-white pt-28">
          <div className="flex flex-col gap-4">
            <div>
              <img src="assets/LogoFooter.png" alt="footer-logo" />
            </div>
            <div>
              FBC Asia Pacific provides leading-edge Outsourcing and Offshore
              programs using Artificial Intelligence, chatbots, and other
              emerging technologies to help companies maximize their revenue
              goals.
            </div>
            <div className="text-white text-base mt-12">Strategic Partner</div>
            <div>
              <img src="assets/SalesRainLogoFooter.png" alt="" />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div>FBC Asia Pacific</div>
            <div className="flex flex-col gap-2">
              <Link
                to="/about"
                className="text-base hover:underline cursor-pointer"
              >
                <div className="text-base hover:underline cursor-pointer">
                  About
                </div>
              </Link>
              <Link
                to="/FBC_Difference"
                className="text-base hover:underline cursor-pointer"
              >
                <div className="text-base hover:underline cursor-pointer">
                  FBC Difference
                </div>
              </Link>
              <Link to="/careers">
                <div className="text-base hover:underline cursor-pointer">
                  Careers
                </div>
              </Link>
              <Link to="/contact">
                <div className="text-base hover:underline cursor-pointer">
                  Contact
                </div>
              </Link>

              {/* <div className="text-base hover:underline cursor-pointer">
                Outsourcing Glossary
              </div> */}
              {/* <div className="text-base hover:underline cursor-pointer">
                FAQ
              </div> */}
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div>Engagement Models</div>
            <div className="flex flex-col gap-2">
              <div>Staff Augmentation</div>
              <div>Seat Leasing</div>
              <div>Outsourcing</div>
              <div>Recruitment Process Outsourcing</div>
              <div>Build, Operate, Transfer</div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div>Get in Touch</div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-4 items-center">
                <BsFillTelephoneFill className="text-white" />
                <div>
                  <div className="flex flex-col gap-y-4">
                    +63 917 509 7752 (The Philippines)
                  </div>
                  <div>+94 11 514 6699 (Sri Lanka)</div>
                </div>
              </div>
              <div className="flex flex-row gap-4 items-center mt-4">
                <div className="w-[30px] ">
                  <img src="assets/EmailVector.png" alt="tele" />
                </div>
                <div>
                  <div className="flex flex-col gap-y-4">
                    <a href="mailto:info@fbcapac.com">info@fbcapac.com</a>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-row gap-4 mt-8 items-center">
                  <div className="w-6 h-6">
                    <Link
                      to="https://www.facebook.com/Faster.Better.Cheaper.AsiaPacific"
                      target="_blank"
                    >
                      <FaFacebookF className="text-white" size={25} />
                    </Link>
                  </div>
                  <div className="w-6 h-6">
                    <Link to="">
                      <FaTwitter className="text-white" size={25} />
                    </Link>
                  </div>
                  <div className="w-6 h-6">
                    <Link
                      to="https://www.instagram.com/fbcapac/"
                      target="_blank"
                    >
                      <FaInstagram className="text-white" size={25} />
                    </Link>
                  </div>
                  <div className="w-6 h-6">
                    <Link
                      to="https://www.linkedin.com/company/fbcasiapacific/"
                      target="_blank"
                    >
                      <FaLinkedinIn className="text-white" size={25} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 bg-[#0C2E4E] px-20">
          <hr className="text-white h-2 w-full" />
          <div className="flex justify-between">
            <div className="flex  text-sm font-light text-gray-400 pt-6 pb-12 gap-1">
              <Link to="/privacy-policy">
                <span className="cursor-pointer" onClick={handleClick}>
                  Privacy Policy{" "}
                </span>
                |
              </Link>
              <Link to="/terms-and-conditions">
                <span className="cursor-pointer" onClick={handleClick}>
                  Terms and Conditions
                </span>
              </Link>
            </div>
            <div className="flex  text-sm font-light text-gray-400 pt-6 pb-12">
              Copyright © 2024 FBC Asia Pacific Pte Ltd. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    );
  } else if (window.innerWidth <= 768) {
    return (
      <div className="flex flex-col">
        <form
          className="bg-[#FF731D] px-4 flex flex-col justify-center items-center text-white gap-4 py-16"
          form
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="text-2xl font-semibold">Stay up to Date</div>
          <div className="font-light text-base">
            Subscribe to our monthly newsletter
          </div>
          <div className="">
            <input
              type="email"
              placeholder="Email"
              id="footerEmail"
              className="h-[40px] w-[362px] px-4 bg-transparent text-white placeholder-white border rounded-lg border-white"
              name="email"
              required
            />{" "}
          </div>
          <div>
            <input
              type="submit"
              value="Send"
              className="w-[187px] h-[57px] rounded-lg bg-white text-[#FF731D]"
            />
          </div>
        </form>
        <div className="bg-[#0C2E4E] px-4 py-8">
          <div>
            <img src="assets/LogoFooter.png" alt="logo" className="w-[200px]" />
          </div>
          <div className="text-white text-base font-light pt-12">
            FBC Asia Pacific provides leading-edge Outsourcing and Offshore
            programs using Artificial Intelligence, chatbots, and other emerging
            technologies to help companies maximize their revenue goals.
          </div>
          <div className="flex flex-row gap-8 py-8">
            <div className="text-white flex flex-col gap-2">
              <div className="text-base underline  font-semibold pb-">
                FBC Asia Pacific
              </div>
              <Link
                to="/about"
                className="text-base hover:underline cursor-pointer"
              >
                <div className="text-base hover:underline cursor-pointer">
                  About
                </div>
              </Link>
              <Link
                to="/FBC_Difference"
                className="text-base hover:underline cursor-pointer"
              >
                <div className="text-base hover:underline cursor-pointer">
                  FBC Difference
                </div>
              </Link>
              <Link to="/careers">
                <div className="text-base hover:underline cursor-pointer">
                  Careers
                </div>
              </Link>
              <Link to="/contact">
                <div className="text-base hover:underline cursor-pointer">
                  Contact
                </div>
              </Link>
            </div>
            <div className="text-white">
              <div className="text-base  font-semibold pb-8 underline">
                Engagement Models
              </div>
              <div>Staff Augmentation</div>
              <div>Seat Leasing</div>
              <div>Outsourcing</div>
              <div>Recruitment Process Outsourcing</div>
              <div>Build, Operate, Transfer</div>
            </div>
          </div>
          <div className="text-lg font-semibold text-white pb-4">
            Get in Touch
          </div>
          <div className="flex flex-col gap-8">
            <div className="text-white items-center flex flex-row gap-8">
              <div>
                <img src="assets/Vector.png" alt="tele" />
              </div>
              <div>
                <div className="flex flex-col gap-y-4">
                  +63 917 509 7752 (The Philippines)
                </div>
                <div>+94 11 514 6699 (Sri Lanka)</div>
              </div>
            </div>
            <div className="text-white items-center flex flex-row gap-8">
              <div>
                <img src="assets/Emailvector.png" alt="tele" />
              </div>
              <div>
                <div className="flex flex-col gap-y-4">
                  <a href="mailto:info@fbcapac.com">info@fbcapac.com</a>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="flex flex-row gap-4 items-center">
                  <div className="w-6 h-6">
                    <FaFacebookF className="text-white" size={25} />
                  </div>
                  <div className="w-6 h-6">
                    <FaTwitter className="text-white" size={25} />
                  </div>
                  <div className="w-6 h-6">
                    <FaInstagram className="text-white" size={25} />
                  </div>
                  <div className="w-6 h-6">
                    <FaLinkedinIn className="text-white" size={25} />
                  </div>
                </div>
              </div>
              <div className="text-white text-lg font-semibold py-4">
                Strategic Partner
              </div>
              <div>
                <img src="assets/SalesRainLogoFooter.png" alt="logo" />
              </div>
              <div className="py-4 bg-[#0C2E4E]">
                <hr className="text-white h-2 w-full" />
                <div className="flex flex-col mx-auto justify-center text-center text-sm font-light text-gray-400 pt-6 ">
                  <div>
                    Copyright @ FBC Asia Pacific Inc. 2024. All Rights Reserved.
                  </div>
                  <div className="flex text-center justify-center  text-sm font-light text-gray-400 pt-6 pb-4 gap-1">
                    <Link to="/terms-and-conditions">
                      <span className="cursor-pointer" onClick={handleClick}>
                        Privacy Policy{" "}
                      </span>
                      |
                      <span className="cursor-pointer" onClick={handleClick}>
                        Terms and Conditions
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Footer;
