import React from 'react'

const AboutOurStory = () => {
  return (
    <div className="flex flex-col lg:px-24 px-4 md:px-16  lg:py-24 md:py-14 py-8 gap-8">
      <div className="text-[#111439] lg:text-5xl font-semibold text-xl text-center lg:text-left">
        Our Story
      </div>
      <div className="flex lg:flex-row flex-col gap-12 lg:gap-40 text-center lg:text-left">
        <div className="lg:h-[114px]">
          FBC Asia Pacific provides leading-edge Outsourcing and Offshore
          programs using Artificial Intelligence, chatbots, and other emerging
          technologies to help companies maximize their revenue goals. Founded
          in 2019, FBC has quickly grown to nearly 1,000 experienced agents at
          delivery centers in the Philippines and Sri Lanka.
        </div>
        <div>
          FBC provides both Office-Based and Work at Home (WAH) programs to
          leading companies including telco, insurance, health-tech and retail.
          To facilitate multilingual contact center services, FBC formed a
          strategic alliance with Mindpearl, a leading BPO provider with centers
          in Fiji, Malaysia and South Africa.
        </div>
      </div>
    </div>
  );
}

export default AboutOurStory